import { message } from 'antd'
import { queryString } from '../utils/helpers'
import CryptoJS from 'crypto-js'
import uuid from 'uuid-random'

const eliteParkingLogo = '/logos/elite-parking-logo.png'
const spacesLogo = '/logos/spaces-logo.png'
const gmpLogo = '/logos/gmp-logo-black.png'
const apcoaLogo = '/logos/apcoa-logo.svg'
const gmpFevicon = '/logos/gmp-favicon.ico'
const apcoaFevicon = '/logos/apcoa-favicon.ico'
const parkplusLogo = '/logos/parkplus-logo.svg'
const oneparkingFavicon = '/logos/oneparking-favicon.png'
const oneparkingLogo = '/logos/oneparking-logo.png'
const garageinnLogo = '/logos/garageinn-logo.png'
const garageinnFavicon = '/logos/garageinn-fevicon.png'
const greenarkingFevicon = '/logos/greenparking-fevicon.png'
const greenparkingLogo = '/logos/greenparking-logo.png'

const defaultThemeValues = {
	themeColor: '#1890ff',
	icon: gmpFevicon,
	logoUrl: gmpLogo,
}

const configs = [
	{
		slug: 'apcoa',
		title: 'Apcoa',
		themeColor: '#78b51b',
		logoUrl: apcoaLogo,
		icon: apcoaFevicon,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.flow-connect.net',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.apcoaflow.com',
			PREPROD: 'https://preprod.flow-connect.net',
		},
	},
	{
		slug: 'gmp',
		title: 'Get My Parking',
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.parkingglobalserver.com',
			'PRODUCTION-US': 'https://api.parkingglobalserver.com'
		},
	},
	{
		slug: 'hmrl',
		title: 'HMRL',
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.parkingglobalserver.com',
			'PRODUCTION-US': 'https://api.parkingglobalserver.com'
		},
	},
	{
		slug: 'apcoapilot',
		title: 'Apcoa Pilot',
		themeColor: '#78b51b',
		logoUrl: apcoaLogo,
		icon: apcoaFevicon,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.flow-connect.net',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.apcoaflow.com',
		},
	},
	{
		slug: 'gmppilot',
		title: 'Pilot Get My Parking',
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.parkingglobalserver.com',
			'PRODUCTION-US': 'https://api.parkingglobalserver.com'
		},
	},
	{
		slug: 'spaces',
		title: 'Spaces',
		themeColor: '#FAD41F',
		logoUrl: spacesLogo,
		icon: spacesLogo,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'http://space-staging-load-balancer-918855242.us-east-1.elb.amazonaws.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.spacesusa.com',
		},
	},
	{
		slug: 'eliteparking',
		title: 'Elite Parking',
		themeColor: '#F32735',
		logoUrl: eliteParkingLogo,
		icon: eliteParkingLogo,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.elitevipallaccess.com',
		},
	},
	{
		slug: 'parkplus',
		title: 'Parkplus',
		themeColor: '#3CAA36',
		logoUrl: parkplusLogo,
		icon: parkplusLogo,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			PRODUCTION: 'https://api.parkplus.io',
		},
	},
	{
		slug: 'oneparking',
		title: 'One Parking',
		themeColor: '#F2CC39',
		logoUrl: oneparkingLogo,
		icon: oneparkingFavicon,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.parkingglobalserver.com',
			'PRODUCTION-US': 'https://api.parkingglobalserver.com'
		},
	},
	{
		slug: 'garageinn',
		title: 'Garage Inn',
		themeColor: '#FF0000',
		logoUrl: garageinnLogo,
		icon: garageinnFavicon,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.parkingglobalserver.com',
			'PRODUCTION-US': 'https://api.parkingglobalserver.com'
		},
	},
	{
		slug: 'greenparking',
		title: 'Green Parking',
		themeColor: '#3DA540',
		logoUrl: greenparkingLogo,
		icon: greenarkingFevicon,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.parkingglobalserver.com',
			'PRODUCTION-US': 'https://api.parkingglobalserver.com'
		},
	},
]

const { REACT_APP_TENANT: tenant, REACT_APP_DEPLOYMENT: deployment, REACT_APP_HMAC_SALT: salt} = process.env

if (!tenant) {
	console.error(`Tenant not defined`)
}

if (!deployment) {
	console.error(`Deployment not defined`)
}

let configData = configs.find((o) => o.slug === tenant.toLowerCase())

configData = {
	...defaultThemeValues,
	...configData,
	API_URL: configData.API_URL[deployment],
	deployment,
	salt
}

const { slug: X_GMP_TENANT, API_URL } = configData


export const fetchHeaders = () => {
	return {
		'content-type': 'application/json',
		X_DEVICE_ID: 'Web',
		X_GMP_TENANT: X_GMP_TENANT,
		X_AUTH_REQUEST_TYPE: 'jwt_operator',
	}
}

export const HMACHeaders = (method, endpoint) => {
	console.log("step4")
	const date = new Date().toGMTString('EEE, dd MMM YYYY HH:mm:ss zzz')
	console.log("step5")
	console.log('salt', salt)
	const signature = `x-date: ${date}\n${method} ${endpoint} HTTP/1.1\nx_gmp_tenant: ${X_GMP_TENANT}`
    console.log('salt', salt)
	const hash = CryptoJS.HmacSHA256(signature, salt)

	const h = CryptoJS.enc.Base64.stringify(hash).toString()


	return {
		'content-type': 'application/json',
		'x-date': date,
		X_DEVICE_ID: 'Web',
		X_GMP_TENANT: X_GMP_TENANT,
		Authorization: `hmac username="${X_GMP_TENANT}", algorithm="hmac-sha256", headers="x-date request-line x_gmp_tenant", signature="${h}"`,
		X_GMP_CORRELATION_ID: uuid(),
	}
}

export const postRequestTo = async (endpoint, data, method, hmac = false) => {
	let headers = fetchHeaders()
	console.log("step1")
	if (hmac) {
		console.log("step2")
		headers = HMACHeaders(method, endpoint.split('?')[0])
	}
	console.log("step3")

	try {
		let request = await fetch(API_URL + endpoint, {
			method: method || 'POST',
			headers: headers,
			...(data && { body: JSON.stringify(data) }),
		})

		const response = await checkWithErrors(request)
		return response
	} catch (e) {
		message.error(e.message)
		throw new Error(e.message)
	}
}

const checkWithErrors = async (req, contentType = 'json') => {
	try {
		const { status } = req

		if (status && Math.round(status / 100) !== 2) {
			if (status === 419) {
				localStorage.clear()
				window.location.reload()
			}

			try {
				const res = contentType === 'json' ? await req.json() : await req.arrayBuffer()
				throw new Error(res.message || 'Something went wrong')
			} catch (e) {
				throw new Error(e.message || 'Something went wrong')
			}
		}

		try {
			const response = status === 204 ? { status: 'OK' } : req.json()
			return response
		} catch (e) {
			return { success: 'OK' }
		}
	} catch (e) {
		throw new Error(e.message)
	}
}

export const getRequestTo = async (endpoint, data, skipInterceptor = false, { avoidErrorNotification } = {}, hmac = false) => {
	let headers = fetchHeaders()
	if (!!hmac) {
		headers = HMACHeaders('GET', endpoint)
	}
	try {
		const requestWithQueries = data && !!Object.keys(data).length
		const httpQuery = requestWithQueries ? '?' + queryString(data) : ''

		let request = await fetch(API_URL + endpoint + httpQuery, { headers: headers })

		if (skipInterceptor) return request

		const response = await checkWithErrors(request)

		return response
	} catch (e) {
		if (!avoidErrorNotification) {
			message.error(e.message)
		}
		throw new Error(e)
	}
}
