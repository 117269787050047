const eliteParkingLogo = '/logos/elite-parking-logo.png'
const spacesLogo = '/logos/spaces-logo.png'
const gmpLogo = '/logos/gmp-logo-black.png'
const apcoaLogo = '/logos/apcoa-logo.svg'
const gmpFevicon = '/logos/gmp-favicon.ico'
const apcoaFevicon = '/logos/apcoa-favicon.ico'
const parkplusLogo = '/logos/parkplus-logo.svg'
const oneparkingFavicon = '/logos/oneparking-favicon.png'
const oneparkingLogo = '/logos/oneparking-logo.png'
const garageinnLogo = '/logos/garageinn-logo.png'
const garageinnFavicon = '/logos/garageinn-fevicon.png'
const greenarkingFevicon = '/logos/greenparking-fevicon.png'
const greenparkingLogo = '/logos/greenparking-logo.png'

const defaultThemeValues = {
	themeColor: '#1890ff',
	icon: gmpFevicon,
	logoUrl: gmpLogo,
}

const configs = [
	{
		slug: 'apcoa',
		title: 'Apcoa',
		themeColor: '#78b51b',
		logoUrl: apcoaLogo,
		icon: apcoaFevicon,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.flow-connect.net',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.apcoaflow.com',
			PREPROD: 'https://preprod.flow-connect.net',
		},
	},
	{
		slug: 'gmp',
		title: 'Get My Parking',
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.parkingglobalserver.com',
			'PRODUCTION-US': 'https://api.parkingglobalserver.com'
		},
	},
	{
		slug: 'hmrl',
		title: 'HMRL',
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.parkingglobalserver.com',
			'PRODUCTION-US': 'https://api.parkingglobalserver.com'
		},
	},
	{
		slug: 'apcoapilot',
		title: 'Apcoa Pilot',
		themeColor: '#78b51b',
		logoUrl: apcoaLogo,
		icon: apcoaFevicon,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.flow-connect.net',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.apcoaflow.com',
		},
	},
	{
		slug: 'gmppilot',
		title: 'Pilot Get My Parking',
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.parkingglobalserver.com',
			'PRODUCTION-US': 'https://api.parkingglobalserver.com'
		},
	},
	{
		slug: 'spaces',
		title: 'Spaces',
		themeColor: '#FAD41F',
		logoUrl: spacesLogo,
		icon: spacesLogo,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'http://space-staging-load-balancer-918855242.us-east-1.elb.amazonaws.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.spacesusa.com',
		},
	},
	{
		slug: 'eliteparking',
		title: 'Elite Parking',
		themeColor: '#F32735',
		logoUrl: eliteParkingLogo,
		icon: eliteParkingLogo,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.elitevipallaccess.com',
		},
	},
	{
		slug: 'parkplus',
		title: 'Parkplus',
		themeColor: '#3CAA36',
		logoUrl: parkplusLogo,
		icon: parkplusLogo,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			PRODUCTION: 'https://api.parkplus.io',
		},
	},
	{
		slug: 'oneparking',
		title: 'One Parking',
		themeColor: '#F2CC39',
		logoUrl: oneparkingLogo,
		icon: oneparkingFavicon,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.parkingglobalserver.com',
			'PRODUCTION-US': 'https://api.parkingglobalserver.com'
		},
	},
	{
		slug: 'garageinn',
		title: 'Garage Inn',
		themeColor: '#FF0000',
		logoUrl: garageinnLogo,
		icon: garageinnFavicon,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.parkingglobalserver.com',
			'PRODUCTION-US': 'https://api.parkingglobalserver.com'
		},
	},
	{
		slug: 'greenparking',
		title: 'Green Parking',
		themeColor: '#3DA540',
		logoUrl: greenparkingLogo,
		icon: greenarkingFevicon,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.parkingglobalserver.com',
			'PRODUCTION-US': 'https://api.parkingglobalserver.com'
		},
	},
]

const { REACT_APP_TENANT: tenant, REACT_APP_DEPLOYMENT: deployment, REACT_APP_HMAC_SALT: salt} = process.env

if (!tenant) {
	console.error(`Tenant not defined`)
}

if (!deployment) {
	console.error(`Deployment not defined`)
}

let configData = configs.find((o) => o.slug === tenant.toLowerCase())

configData = {
	...defaultThemeValues,
	...configData,
	API_URL: configData.API_URL[deployment],
	deployment,
	salt
}


const updateWebsiteMetaData = ({ title, icon }) => {
	// Favicon Update
	const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
	link.type = 'image/x-icon'
	link.rel = 'shortcut icon'
	link.href = icon
	document.getElementsByTagName('head')[0].appendChild(link)

	// Update Title
	document.title = `${title}  ${configData.deployment.toUpperCase() !== 'PRODUCTION' ? `(${configData.deployment.toLowerCase()})` : ''}`
}

const updateSiteMetaData = () => {
	const { title, icon } = configData

	updateWebsiteMetaData({ title, icon })
}

updateSiteMetaData()

export default { ...configData, navbarHeight: `65px`, footerHeight: `80px` }
