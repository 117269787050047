import React, { Component } from 'react'
import { Page, Wrapper, Logo } from '../sharedComponents/styles'
import { Card, Input, Form, Icon, Button, message } from 'antd'
import { postRequestTo } from '../utils/requestComponents'
import { Typography } from 'antd'
import styled from 'styled-components'
import PasswordSuccess from './PasswordSuccess'
import Translation from './Translation'

export const InvalidLink = styled.img`
	max-width: 50vw;
	height: ${(props) => props.height};
	width: ${(props) => props.height};
	content: url('/images/password-link-expired.svg');
	margin: 2rem 0;
`

const { Title, Text } = Typography
const status = {
	SUCCESS: 'success',
	ERROR: 'error',
	LOADING: 'loading',
}
const PasswordCard = styled(Card)`
	box-shadow: 0px 0px 25px rgba(69, 107, 132, 0.15);
	border-radius: 4px;
	min-width: 300px;
	@media only screen and (min-device-width: 375px) {
		min-width: 330px;
	}

	@media only screen and (min-device-width: 425px) {
		min-width: 360px;
	}
`

class ResetPassword extends Component {
	state = {
		confirmDirty: false,
		verified: status.LOADING,
		loading: false,
		changed: false,
	}

	componentDidMount() {
		const params = new URLSearchParams(this.props.history.location.search)
		const { token, expiresOn, language } = {
			token: params.get('token'),
			expiresOn: params.get('expiresOn'),
			language: params.get('language'),
		}
		try {
			Translation.changeLanguage(language)
			this.setState({ language })
		} catch (e) {
			Translation.changeLanguage('en')
			this.setState({ language: 'en' })
		}

		if (!token || !expiresOn) return this.setState({ verified: status.ERROR, message: Translation.invalidLink, errorMessage: Translation.contactSupport })
		if (Date.now() > expiresOn) return this.setState({ verified: status.ERROR, message: Translation.linkExpired, errorMessage: Translation.linkExpiredText })

		this.setState({ verified: status.SUCCESS, token })
	}

	handleSubmit = (e) => {
		e.preventDefault()
		this.props.form.validateFields(async (err, values) => {
			if (!!err) return message.error(Translation.invalidFieldValues)
			const data = {
				newPassword: values.password1,
				token: values.token,
			}

			try {
				this.setState({ loading: true })
				console.log("resert password1")
				const response = await postRequestTo(`/authentication/v1/consumer/resetPassword`, data, 'POST', true)
				// if (!!response) this.props.history.replace('/passwordChanged')
				if (!!response) this.setState({ changed: true })
				else throw new Error()
			} catch (e) {
				message.error(Translation.genericErrorMessage)
			} finally {
				this.setState({ loading: false })
			}
		})
	}

	compareToFirstPassword = (rule, value, callback) => {
		const { form } = this.props
		if (value && value !== form.getFieldValue('password1')) {
			callback(Translation.confirmPasswordFailed)
		} else {
			 callback()
		}
	}

	validateToNextPassword = (rule, value, callback) => {
		const { form } = this.props
		if (value && this.state.confirmDirty) {
			form.validateFields(['password2'], { force: true })
		}
		callback()
	}

	handleConfirmBlur = (e) => {
		const { value } = e.target
		this.setState({ confirmDirty: this.state.confirmDirty || !!value })
	}

	render() {
		const { getFieldDecorator } = this.props.form
		const { verified, token, loading, message, errorMessage, changed } = this.state
		return (
			<Page>
				{verified === status.SUCCESS && !changed && (
					<Wrapper style={{ padding: 0, margin: 0 }}>
						<Logo style={{ marginBottom: '2rem' }} />
						<PasswordCard title={Translation.resetPasswordTitle} headStyle={{ textAlign: 'center', color: '#000000a6', fontSize: 15 }}>
							<Form onSubmit={this.handleSubmit} className='login-form'>
								<Form.Item hasFeedback help={Translation.passwordHelp}>
									{getFieldDecorator('password1', {
										rules: [
											{ required: true, message: `${Translation.inputPasswordMessage}` },
											{ min: 6, message: `${Translation.passwordHelpMin}` },
											{ max: 20, message: `${Translation.passwordHelpMax}` },
											{ validator: this.validateToNextPassword },
										],
									})(<Input.Password prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={Translation.newPasswordPlaceholder} />)}
								</Form.Item>
								<Form.Item hasFeedback>
									{getFieldDecorator('password2', {
										rules: [{ required: true, message: `${Translation.inputPasswordMessage}` }, { validator: this.compareToFirstPassword }],
									})(
										<Input.Password
											prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
											placeholder={Translation.confirmPasswordPlaceholder}
											onBlur={this.handleConfirmBlur}
										/>,
									)}
								</Form.Item>
								{getFieldDecorator('token', { initialValue: token })}
								<Button loading={loading} style={{ width: '100%' }} type='primary' htmlType='submit'>
									{Translation.changePasswordButtonText}
								</Button>
							</Form>
						</PasswordCard>
					</Wrapper>
				)}
				{verified !== status.SUCCESS && !changed && (
					<Wrapper className='card'>
						<Logo />
						<InvalidLink />
						<Title level={4} style={{ color: '#262626' }}>
							{message}
						</Title>
						{!!errorMessage && <Text>{errorMessage}</Text>}
					</Wrapper>
				)}
				{!!changed && <PasswordSuccess />}
			</Page>
		)
	}
}

export default Form.create()(ResetPassword)
